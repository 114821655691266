import request from '../utils/request'


/**
 首页接口地址：https://www.keyifazhan.com:9998/api/cats
 /api/arts,根据导航id我给你返回内容列表
 * @return
 */
export function getCats(data) {
    return request({
        url: '/api/cats',
        method: 'get',
        params: data
    })
}

/**
 企业资质调用https://www.keyifazhan.com:9998/api/arts?categoryId=8&pagination=false&containsContent=true

 工程案例调https://www.keyifazhan.com:9998/api/arts?categoryId=9&pagination=true&containsContent=false
 分页带上：在加上pageNum和pageSize

 公司动态调https://www.keyifazhan.com:9998/api/arts?categoryId=6&pagination=true&containsContent=false&pageSize=&pageNum=

 行业资讯调https://www.keyifazhan.com:9998/api/arts?categoryId=13&pagination=true&containsContent=false&pageSize=&pageNum=

 人才招聘：https://www.keyifazhan.com:9998/api/arts?categoryId=10&pagination=false&containsContent=true
 * @return
 */
export function getArts(data) {
    return request({
        url: '/api/arts',
        method: 'get',
        params: data
    })
}


/**
 公司简介
 https://www.keyifazhan.com:9998/api/artfir?categoryId=2
 企业文化
 https://www.keyifazhan.com:9998/api/artfir?categoryId=7
 /api/arts,根据导航id我给你返回内容列表

 联系我们：https://www.keyifazhan.com:9998/api/artfir?categoryId=11
 * @return
 */
export function getArtfir(data) {
    return request({
        url: '/api/artfir',
        method: 'get',
        params: data
    })
}


/**
 详情调https://www.keyifazhan.com:9998/api/art?articleId=
 * @return
 */
export function getArt(data) {
    return request({
        url: '/api/art',
        method: 'get',
        params: data
    })
}


/**
 图片调：https://www.keyifazhan.com:9998/api/ad?code=
 关于我们code=about_ad，工程案例页projs_ad,人才招聘jobs_ad,新闻动态news_ad,联系我们contact_ad
 * @return
 */
export function getAd(data) {
    return request({
        url: '/api/ad',
        method: 'get',
        params: data
    })
}
